// extracted by mini-css-extract-plugin
export var benefitItem = "BenefitsSection-module--benefitItem--c85d9";
export var benefitsWrapper = "BenefitsSection-module--benefitsWrapper--9fed4";
export var headingH3 = "BenefitsSection-module--headingH3--3356e";
export var image = "BenefitsSection-module--image--327ee";
export var list = "BenefitsSection-module--list--2eea6";
export var opacityEnter = "BenefitsSection-module--opacityEnter--4ea46";
export var opacityExit = "BenefitsSection-module--opacityExit--57c30";
export var paragraph = "BenefitsSection-module--paragraph--164b1";
export var rollDown = "BenefitsSection-module--rollDown--743fa";
export var rollUp = "BenefitsSection-module--rollUp--89452";
export var root = "BenefitsSection-module--root--d246e";
export var slideInDown = "BenefitsSection-module--slideInDown--52f69";
export var slideOutUp = "BenefitsSection-module--slideOutUp--63a55";
export var splashEnter = "BenefitsSection-module--splashEnter--d784f";
export var splashExit = "BenefitsSection-module--splashExit--e6122";
export var title = "BenefitsSection-module--title--67ca3";
export var wrapper = "BenefitsSection-module--wrapper--9d921";