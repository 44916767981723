// extracted by mini-css-extract-plugin
export var headingH3 = "Statistics-module--headingH3--f7d38";
export var opacityEnter = "Statistics-module--opacityEnter--f3325";
export var opacityExit = "Statistics-module--opacityExit--78c42";
export var paragraph = "Statistics-module--paragraph--0af56";
export var rollDown = "Statistics-module--rollDown--3ee3e";
export var rollUp = "Statistics-module--rollUp--c4132";
export var root = "Statistics-module--root--520f1";
export var slideInDown = "Statistics-module--slideInDown--1b3c7";
export var slideOutUp = "Statistics-module--slideOutUp--38aa7";
export var splashEnter = "Statistics-module--splashEnter--430c1";
export var splashExit = "Statistics-module--splashExit--2e5e0";